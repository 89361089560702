.main-section {
  display: flex;
  position: relative;
  height: 100vh;
}
.text-container {
  padding: 5%;
  width: 60%;
}
.image-container {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -11;
}
.contents {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 2% 10% 0 10%;
  padding: 15px 0;
  /* border: 2px solid red; */
}
