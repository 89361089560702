.text {
  text-align: center;
  padding: 20px;
  margin-top: 7%;
}
.main-container {
  display: flex;
  height: 100vh;
}
.left-side {
  flex: 6;
  margin: 10% 0;
  padding-left: 100px;
}
.right-side {
  flex: 6;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 50% !important;
  padding-left: 100px;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  left: 10px;
}
.swiper-pagination-bullet {
  border: 2px solid black;
  opacity: 0.6;
  background: none;
  width: 6px;
  height: 6px;
}
.swiper-pagination-bullet-active {
  border: none;
  background: grey;
  width: 12px;
  height: 12px;
}

.number {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  color: black;
  border: 2px solid black;
}
.number-focused {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #0170dc;
  color: white;
}
.swiper-slide.active {
  opacity: 1;
}
.swiper-slide.inactive {
  opacity: 0.5;
}
.swiper-wrapper {
  top: -27%;
}

.imagecontainer {
  padding: 20px;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagecontainer #sliderimage1 {
  position: absolute;
}
.imagecontainer #sliderimage2 {
  position: relative;
}
.imagecontainer #sliderimage3 {
  position: absolute;
}
.imagecontainer #sliderimage4 {
  position: absolute;
  left: -5%;
  top: 20%;
  z-index: 999;
}
.imagecontainer #sliderimage5 {
  position: absolute;
  bottom: 17%;
  right: 17%;
}
