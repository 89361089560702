.account-container {
  display: flex;
  align-items: center;
}
.circle {
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
  background-color: lightgray;
}
